<template>
  <b-container class="mt-5">
    <b-modal
      id="alertContinue"
      class="main-modal"
      centered
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <template>
        <h4 class="font-weight-bold">
          <span class="text-danger">{{ $t("insurance.insurance") }}: </span>
        </h4>
      </template>
      <template>
        <P class="text-center font-weight-bold text-black">
          {{ $t("insurance.completeInsuranceData") }}
        </P>
        <div class="d-flex justify-content-center mt-3">
          <span class="w-40 p-2">
            <b-button
              @click="startNew"
              variant="danger"
              class="popupButton iq-border-radius-5 w-100"
            >
              <span class="mr-2">{{ $t("insurance.newStart") }}</span>
            </b-button>
          </span>
          <span class="w-40 p-2">
            <b-button
              variant="warning"
              class="popupButton text-white iq-border-radius-5 w-100"
              @click="countinue"
            >
              <span>{{ $t("insurance.dataComplement") }}</span></b-button
            >
          </span>
        </div>
      </template>
    </b-modal>
    <div id="pay-popup" v-show="pay_popup" title="دفع" size="lg">
      <b-card>
        <div id="embed-target1"></div>
      </b-card>
    </div>
    <b-row>
      <b-col md="2" class="d-flex justify-content-end">
        <p
          @click="reverseCompnent"
          class="text-warning font-size-16 font-weight-bold cursor-pointer"
          v-if="selectedComponent != 'userInfo'"
        >
          <i class="las la-angle-right font-size-16"></i>
          {{ $t("endUser.back") }}
        </p>
      </b-col>
      <b-col md="8">
        <div class="stepper d-flex justify-content-between mb-2">
          <div
            class="stepper-item position-relative"
            v-for="(status, index) in allComponent"
            :key="index"
            :class="{
              'stepper-item--completed':
                index <=
                allComponent.findIndex(
                  (component) => component.value === selectedComponent
                ),
            }"
          >
            <div
              class="stepper-item__step position-relative"
              :class="{ 'ml-3': index != 0 }"
            >
              <div class="stepper-item__circle">
                <span v-if="index != 0">{{ index }}</span>
              </div>
              <div class="stepper-item__line"></div>
            </div>
            <div
              class="stepper-item__status text-uppercase"
              v-if="status.value != 'final'"
            >
              {{ status.name }}
            </div>
          </div>
        </div>
      </b-col>
      <b-col md="2"> </b-col>
    </b-row>
    <b-row class="justify-content-center mt-4">
      <!-- selectedComponent === 'fees' || selectedComponent === 'installment' || selectedComponent === 'final' ? '12' : '8' -->
      <b-col
        :md="
          selectedComponent === 'fees' ||
          selectedComponent === 'installment' ||
          selectedComponent === 'final'
            ? '12'
            : '8'
        "
      >
        <transition
          name="slide-up"
          appear
          mode="out-in"
          :duration="300"
          class="w-100"
        >
          <keep-alive>
            <component
              :is="selectedComponent"
              :info="insurranceData"
              @saveInsurance="saveInsurance"
              @reverse="reverseCompnent"
              @changeForm="changeComponent"
            ></component>
          </keep-alive>
        </transition>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
/* eslint-disable */
import { core } from "@/config/pluginInit";
import userInfo from "../components/insuranceSteps/userInfo";
import packageInfo from "../components/insuranceSteps/packageInfo";
import fees from "../components/insuranceSteps/fees";
import installment from "../components/insuranceSteps/installment";
import final from "../components/insuranceSteps/final";
import insuranceServices from "../services/insurance";
import websiteServices from "@/modules/userProfile/services/userProfile";
import Payment from "@/Utils/payment";
export default {
  mounted() {
    core.index();
    if (localStorage.getItem("insuranceStep")) {
      // this.insurranceData = localStorage.getItem('insuranceInfo') ? JSON.parse(localStorage.getItem('insuranceInfo')) : ''
      this.$bvModal.show("alertContinue");
      this.insurranceData = JSON.parse(localStorage.getItem("insuranceInfo"));
    }
  },
  components: {
    userInfo,
    packageInfo,
    fees,
    installment,
    final,
  },
  data() {
    return {
      pay_popup: false,
      // allComponent: [
      //     { value: 'userInfo', name: this.$t('insurance.customerData') }, { value: 'packageInfo', name: this.$t('insurance.shipmentInformation') }, { value: 'fees', name: this.$t('insurance.quotations') }, { value: 'installment', name: this.$t('insurance.payments') }, { value: 'final', name: 'final' }],
      selectedComponent: "userInfo",
      insurranceData: {},
    };
  },
  computed: {
    allComponent() {
      return [
        { value: "userInfo", name: this.$t("insurance.customerData") },
        {
          value: "packageInfo",
          name: this.$t("insurance.shipmentInformation"),
        },
        { value: "fees", name: this.$t("insurance.quotations") },
        { value: "installment", name: this.$t("insurance.payments") },
        { value: "final", name: "final" },
      ];
    },
  },
  methods: {
    changeComponent(data) {
      this.insurranceData = { ...this.insurranceData, ...data };
      localStorage.setItem(
        "insuranceInfo",
        JSON.stringify(this.insurranceData)
      );
      const indexStepper = this.allComponent.findIndex(
        (stepper) => stepper.value === this.selectedComponent
      );
      this.selectedComponent = this.allComponent[indexStepper + 1].value;
    },
    async goToPayment(data) {
      sessionStorage.setItem("initiateData", JSON.stringify(data));
      const returnUrl = window.location.origin + "/payment-status";
      const Pay = new Payment(data.gateway.toLowerCase(), data);
      const url = await Pay.paymentConfig();

      if (data.gateway.toLowerCase() === "hyperpay") {
        this.hyperpayForm = true;

        window.wpwlOptions = {
          onReady: function () {
            console.log("HyperPay widget is ready");
          },
          onComplete: function (result) {
            console.log("Payment completed:", result);
          },
        };

        const script = document.createElement("script");
        script.src = url + data.checkout_id;
        script.onload = await function () {
          console.log("HyperPay script loaded");
        };
        document.body.appendChild(script);

        const form = document.createElement("form");
        form.classList.add("paymentWidgets");
        form.setAttribute("data-brands", "VISA MASTER AMEX");
        form.action = returnUrl;
        document.querySelector(".test-form").appendChild(form);

        this.addEscListener();
      }
    },
    saveInsurance(data) {
      insuranceServices
        .createInsurance({ ...this.insurranceData, ...data })
        .then((res) => {
          this.insurranceData.insurance_id = res.data.id;
          // eslint-disable-next-line eqeqeq
          if (data.payment_method_id == 2) {
            this.selectedComponent = "final";
            localStorage.removeItem("insuranceStep");
            localStorage.removeItem("insuranceInfo");
          } else if (data.payment_method_id == 1) {
            // this.payWithCard(res.data)
            websiteServices.initiate(res.data).then((res) => {
              this.goToPayment(res.data);
            });
            window.Checkout.showEmbeddedPage("#embed-target1");
            localStorage.removeItem("insuranceStep");
            localStorage.removeItem("insuranceInfo");
          }
        });
    },
    reverseCompnent() {
      const indexStepper = this.allComponent.findIndex(
        (stepper) => stepper.value === this.selectedComponent
      );
      this.selectedComponent = this.allComponent[indexStepper - 1].value;
    },
    startNew() {
      localStorage.removeItem("insuranceStep");
      localStorage.removeItem("insuranceInfo");
      window.location.reload();
      this.$bvModal.hide("alertContinue");
    },
    countinue() {
      this.selectedComponent = localStorage.getItem("insuranceStep");
      this.$bvModal.hide("alertContinue");
    },
  },
};
</script>
<style lang="scss">
.stepper-item__status {
  position: absolute;
  right: 40%;
  top: -14px;
}
.stepper {
  .cancelled-order {
    background: #f00;
    padding: 4px 8px;
    border-radius: 4px;
    span {
      color: #fff;
      font-weight: 600;
    }
  }
  .stepper-item {
    position: relative;
    .stepper-item__circle {
      position: relative;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--iq-secondary);
      color: #fff;
      z-index: 2;
      font-weight: bold;
      font-size: 12px;
      line-height: 0;
    }
    .stepper-item__status {
      color: var(--iq-secondary);
    }
    &:not(:last-child) {
      flex-grow: 1;
      .stepper-item__line {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        width: calc(100% + 15px);
        height: 4px;
        background: var(--iq-secondary);
      }
    }
    @media (max-width: 991px) {
      .stepper-item__circle {
        width: 20px;
        height: 20px;
        i {
          font-size: 0.8rem;
        }
      }
      .stepper-item__status {
        font-size: 12px;
      }
    }
    &.stepper-item--completed {
      .stepper-item__circle {
        background: var(--iq-primary);
        box-shadow: inset 0px 3px 6px #00000096;
      }
      .stepper-item__status {
        font-weight: bold;
        color: var(--iq-primary);
      }
      .stepper-item__line {
        background: var(--iq-primary);
      }
    }
  }
}
[dir="rtl"] .stepper-item {
  .stepper-item__line {
    right: 0px;
    left: auto;
  }
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.6s ease-in-out;
}
.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(40px);
  opacity: 0;
}
</style>
