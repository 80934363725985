<template>
  <div class="user-registeration-service-box mt-4">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        class="doctor-individual-form"
        @submit.prevent="handleSubmit(submit)"
      >
        <input-form
          class="mb-3 inp"
          v-model="companyData.name"
          validate="required"
          name="company_name"
          placeholder="اسم الشركة"
          label="اسم الشركة"
        />
        <input-form
          class="mb-3 inp"
          v-model="companyData.ownerName"
          validate="required"
          name="company_owner_name"
          placeholder="إسم مالك الشركة"
          label="إسم مالك الشركة"
        />
        <country-code
          validate="required"
          name="phone_number"
          placeholder="رقم الهاتف"
          label="رقم الهاتف"
          v-model="companyData.phone"
          id="phone_number_individual"
          @onSelect="onSelect"
        />
        <input-form
          class="mb-3 inp"
          v-model="companyData.ownerNationalID"
          validate="required|numeric"
          name="id"
          placeholder="رقم الهوية"
          label="رقم الهوية"
        />
        <!-- check-box-for-commission -->
        <b-row class="mt-4" v-if="true">
          <b-col md="12">
            <div class="d-flex align-items-center">
              <!-- Checkbox -->
              <b-form-checkbox
                name="agree"
                :value="true"
                :unchecked-value="false"
                required
                class="mr-2 mb-0"
                style="transform: scale(1.2)"
              >
                ></b-form-checkbox
              >

              <!-- Text next to checkbox -->
              <span class="joining-label mb-0" style="font-size: 16px">
                أوافق على إضافة نسبة
                <strong>10 %</strong>
                على قيمة كل طلب
              </span>
            </div>
          </b-col>
        </b-row>
        <!-- submit -->
        <div class="d-flex justify-content-center">
          <b-button
            variant="primary"
            type="submit"
            class="px-5 py-2 iq-border-radius-5 text-center font-weight-bold user-registeration-service-box-btn"
          >
            <span class="mx-2"> تسجيل</span>
          </b-button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
/*eslint-disable*/
import joiningServices from "@/modules/joining/services/join";
import { core } from "@/config/pluginInit";

export default {
  data() {
    return {
      commissionPercentage: 0,
      companyData: {
        name: "",
        ownerName: "",
        address: "",
        phone: "",
        ownerNationalID: "",
        countryCode: "",
      },
    };
  },
  methods: {
    onSelect(data) {
      this.companyData.countryCode = data;
    },
    submit() {
      this.submitLoading = true;
      joiningServices
        .joinCompany(this.companyData)
        .then((res) => {
          core.showSnackbar("success", res.data.message);
          this.$router.push("/user-profile/user-home");
          window.location.href = VUE_APP_FRONT_COMPANY_TRANSPORTATION_LINK;
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
  },
};
</script>
<style>
.testphone {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
  margin-top: 37px;
}
</style>
