<template>
  <b-container>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form
        class="position-relative form-top p-4"
        @submit.prevent="handleSubmit(registerUser)"
      >
        <b-row>
          <b-col lg="6">
            <input-form
              class="mb-3"
              v-model="newUser.national_id"
              :validate="{
                required: true,
                numeric: true,
                digits: 10,
                regex: /^[1-2][0-9]*$/,
              }"
              :placeholder="$t('auth.IdNumber')"
              :name="$t('auth.IdNumberWithCondition')"
              :label="$t('auth.IdNumber')"
            />
          </b-col>
          <b-col lg="6">
            <country-code
              :validate="'required|numeric|min:9|max:11'"
              :placeholder="$t('auth.phoneNumber')"
              :name="$t('auth.phoneNumber')"
              :label="$t('auth.phoneNumber')"
              v-model="newUser.phone"
              id="phone-code-primary_phone_number"
              @onSelect="onSelect"
            />
            <!-- <input-form
                class="mb-3"
                v-model="newUser.phone"
                :validate="'required|numeric|min:9|max:11'"
                :placeholder="$t('auth.phoneNumber')"
                :name="$t('auth.phoneNumber')"
                :label="$t('auth.phoneNumber')"
            /> -->
          </b-col>
          <!-- <b-col lg="2">
            <div class="d-flex flex-column">
            <label >{{ $t('auth.countryCode') }}</label>
            <vue-country-code
                v-model="newUser.country_code"
                @onSelect="onSelect"
                :onlyCountries="['sa', 'eg']"
                :dropdownOptions="{ disabledDialCode: true }"
                :enabledCountryCode= true
                defaultCountry="sa"
                class="testphone"
            />
            </div>
          </b-col> -->
          <b-col lg="6">
            <input-form
              class="mb-3"
              v-model="newUser.name"
              :validate="'required'"
              :placeholder="$t('auth.userName')"
              :name="$t('auth.userName')"
              :label="$t('auth.userName')"
            />
          </b-col>
          <b-col lg="6">
            <input-form
              class="mb-3"
              v-model="newUser.email"
              :validate="'required'"
              :placeholder="$t('auth.email')"
              :name="$t('auth.email')"
              :label="$t('auth.email')"
              type="email"
            />
          </b-col>
          <b-col lg="6">
            <input-form
              class="mb-3"
              type="password"
              :validate="'required|min:8'"
              v-model="newUser.password"
              :placeholder="$t('auth.password')"
              name="password"
              :label="$t('auth.password')"
            />
          </b-col>
          <b-col lg="6">
            <input-form
              class="mb-3"
              type="password"
              v-model="newUser.confirm_password"
              :validate="'required|confirmed:password'"
              :placeholder="$t('auth.passwordChecked')"
              :name="$t('auth.passwordChecked')"
              :label="$t('auth.passwordChecked')"
            />
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center mt-4">
          <b-button
            class="text-white w-50 py-2"
            variant="primary"
            type="submit"
            v-if="!loadingRegistrationButton"
          >
            <span>{{ $t("auth.createAccount") }}</span>
          </b-button>
          <b-button
            v-else
            class="text-white w-50 py-2"
            variant="primary"
            disabled
          >
            <spinner-loading text="saving" />
          </b-button>
        </div>
        <div class="d-flex justify-content-center mt-2">
          إذا كان لديك حساب،

          <b-link
            class="text-primary px-2"
            @click="$router.push('/auth/login')"
          >
            برجاء تسجيل الدخول مباشرة
          </b-link>
        </div>
      </b-form>
    </ValidationObserver>
  </b-container>
</template>
<script>
/*eslint-disable */
import { core } from "@/config/pluginInit";
import authService from "../services/auth.js";

export default {
  data() {
    return {
      loadingRegistrationButton: false,
      newUser: {
        name: "",
        national_id: "",
        email: "",
        phone: "",
        password: "",
        confirm_password: "",
        country_code: "",
      },
      fullNumber: "",
    };
  },
  mounted() {
    core.index();
  },
  methods: {
    registerUser() {
      this.loadingRegistrationButton = true;
      authService
        .register(this.newUser)
        .then((res) => {
          core.showSnackbar("success", res.data.message);
          localStorage.setItem(
            "userInfoRegistration",
            JSON.stringify(res.data?.data)
          );
          this.$router.push({
            name: "otb",
            params: {
              phoneNumber: this.newUser.phone, // 0566502430
              country_code: this.newUser.country_code,
            },
          });
        })
        .finally(() => {
          this.loadingRegistrationButton = false;
        });
    },
    onSelect(data) {
      this.newUser.country_code = data;
    },
  },
};
</script>
<style>
.already-email {
  color: red;
}
.phone-input .form-control {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.testphone {
  border-bottom-right-radius: 9px !important;
  border-top-right-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
}
</style>
