var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"mt-5"},[_c('b-modal',{staticClass:"main-modal",attrs:{"id":"alertContinue","centered":"","hide-header":"","no-close-on-backdrop":"","no-close-on-esc":"","hide-footer":""}},[[_c('h4',{staticClass:"font-weight-bold"},[_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t("insurance.insurance"))+": ")])])],[_c('P',{staticClass:"text-center font-weight-bold text-black"},[_vm._v(" "+_vm._s(_vm.$t("insurance.completeInsuranceData"))+" ")]),_c('div',{staticClass:"d-flex justify-content-center mt-3"},[_c('span',{staticClass:"w-40 p-2"},[_c('b-button',{staticClass:"popupButton iq-border-radius-5 w-100",attrs:{"variant":"danger"},on:{"click":_vm.startNew}},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t("insurance.newStart")))])])],1),_c('span',{staticClass:"w-40 p-2"},[_c('b-button',{staticClass:"popupButton text-white iq-border-radius-5 w-100",attrs:{"variant":"warning"},on:{"click":_vm.countinue}},[_c('span',[_vm._v(_vm._s(_vm.$t("insurance.dataComplement")))])])],1)])]],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pay_popup),expression:"pay_popup"}],attrs:{"id":"pay-popup","title":"دفع","size":"lg"}},[_c('b-card',[_c('div',{attrs:{"id":"embed-target1"}})])],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"2"}},[(_vm.selectedComponent != 'userInfo')?_c('p',{staticClass:"text-warning font-size-16 font-weight-bold cursor-pointer",on:{"click":_vm.reverseCompnent}},[_c('i',{staticClass:"las la-angle-right font-size-16"}),_vm._v(" "+_vm._s(_vm.$t("endUser.back"))+" ")]):_vm._e()]),_c('b-col',{attrs:{"md":"8"}},[_c('div',{staticClass:"stepper d-flex justify-content-between mb-2"},_vm._l((_vm.allComponent),function(status,index){return _c('div',{key:index,staticClass:"stepper-item position-relative",class:{
            'stepper-item--completed':
              index <=
              _vm.allComponent.findIndex(
                function (component) { return component.value === _vm.selectedComponent; }
              ),
          }},[_c('div',{staticClass:"stepper-item__step position-relative",class:{ 'ml-3': index != 0 }},[_c('div',{staticClass:"stepper-item__circle"},[(index != 0)?_c('span',[_vm._v(_vm._s(index))]):_vm._e()]),_c('div',{staticClass:"stepper-item__line"})]),(status.value != 'final')?_c('div',{staticClass:"stepper-item__status text-uppercase"},[_vm._v(" "+_vm._s(status.name)+" ")]):_vm._e()])}),0)]),_c('b-col',{attrs:{"md":"2"}})],1),_c('b-row',{staticClass:"justify-content-center mt-4"},[_c('b-col',{attrs:{"md":_vm.selectedComponent === 'fees' ||
        _vm.selectedComponent === 'installment' ||
        _vm.selectedComponent === 'final'
          ? '12'
          : '8'}},[_c('transition',{staticClass:"w-100",attrs:{"name":"slide-up","appear":"","mode":"out-in","duration":300}},[_c('keep-alive',[_c(_vm.selectedComponent,{tag:"component",attrs:{"info":_vm.insurranceData},on:{"saveInsurance":_vm.saveInsurance,"reverse":_vm.reverseCompnent,"changeForm":_vm.changeComponent}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }